import Banner from "./components/Banner";
import Footer from "./components/Footer";
import FourthBanner from "./components/FourthBanner";
import "./components/home.css";
import HomeNavigationBar from "./components/HomeNavigationBar";
import SecondBanner from "./components/SecondBanner";
import ThirdBanner from "./components/ThirdBanner";
export default function Home() {
  return (
    <div>
      <HomeNavigationBar />

      <Banner />
      <SecondBanner />
      <ThirdBanner />
      <FourthBanner />
      <Footer/>
    </div>
  );
}

import { createBrowserRouter } from "react-router-dom";

import Login from "../components/login/Login";
import Signup from "../components/signUp/Signup";
import ForgotPassword from "../components/login/forgetPassword";
import Root from "../layout/root";

import ResetPassword from "../components/login/resetPassword";
import IntroFlow from "../components/signUp/introFlow";
import NameYourCompany from "../components/signUp/nameyourcompany";
import AboutBusiness from "../components/signUp/aboutbusiness";
import SharesAndPaids from "../components/signUp/sharesandpaids";
import BusinessContactDetails from "../components/signUp/businesscontactdetails";
import WhoWillBeTheDirectors from "../components/signUp/whowillbethedirectors";
import ShareHolders from "../components/signUp/Shareholders";
import ReviewSubmission from "../components/signUp/reviewSubmission";
import SubmittedSuccessfull from "../components/signUp/submittedsuccessfull";
import Selectplan from "../components/signUp/selectplan";
import MakePayment from "../components/signUp/makepayment";
import YourCompanies from "../components/dashboardsfiles/yourcompanies";
import UpdatePassword from "../components/dashboardsfiles/updatepassword";
import Registercompany from "../components/dashboardsfiles/registercompany";
import Profile from "../components/dashboardsfiles/profile";
import Dashboards from "../components/dashboardsfiles/dashboards";
import Invoices from "../components/dashboardsfiles/invoices";
import Expenses from "../components/dashboardsfiles/expenses";
import Statements from "../components/dashboardsfiles/statements";
import Accounting from "../components/dashboardsfiles/accounting";
import Legal from "../components/dashboardsfiles/legal";
import Banks from "../components/dashboardsfiles/banks";
import Perks from "../components/dashboardsfiles/perks";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "../ui/Notfound";
import Company from "../components/dashboardsfiles/Company";
import CompanyProfile from "../components/dashboardsfiles/CompanyProfile";
import CompanyDirectors from "../components/dashboardsfiles/CompanyDirectors";
import CompanyShareholders from "../components/dashboardsfiles/CompanyShareholders";
import Documents from "../components/dashboardsfiles/Documents";
import UserManagement from "../components/dashboardsfiles/Admin/UserManagement";
import AdminDashboard from "../layout/DashboardLayout/AdminDashboard";
import UserDashboard from "../layout/DashboardLayout/UserDashboard";
import AdminIndex from "../components/dashboardsfiles/Admin/AdminIndex";

import PaymentRecords from "../components/dashboardsfiles/Admin/PaymentRecords";
import BankDetails from "../components/dashboardsfiles/BankDetails";
import UserDetails from "../components/dashboardsfiles/Admin/UserDetails";
import AdminCompanyDetails from "../components/dashboardsfiles/Admin/AdminCompanyDetails";
import AdminUpdatePassword from "../components/dashboardsfiles/Admin/AdminUpdatePassword";
import AdminProfileSetting from "../components/dashboardsfiles/Admin/AdminProfileSetting";
import AdminEditUserProfile from "../components/dashboardsfiles/Admin/AdminEditUserProfile";
import EditCompanyInformation from "../components/dashboardsfiles/Admin/EditCompanyInformation";
import AllCompanyList from "../components/dashboardsfiles/Admin/AllCompanyList";
import OTPVerification from "../components/signUp/OTPVerification";
import TermsAndCondition from "../components/userManual/termsAndCondition";
import PaymentDetails from "../components/dashboardsfiles/Admin/PaymentDetails";
import Billing from "../components/dashboardsfiles/User/UserUi/Billing";
import UserPaymentDetails from "../components/dashboardsfiles/User/UserPaymentDetails";
import Home from "../components/home/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/termsAndConditions",
        element: <TermsAndCondition />,
      },
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/verification/:email",
        element: <OTPVerification />,
      },
      {
        path: "/reset-password/:accessToken",
        element: <ResetPassword />,
      },
      {
        path: "/signup/intro",
        element: (
          <ProtectedRoute>
            <IntroFlow />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup/nameyourcompany",
        element: (
          <ProtectedRoute>
            <NameYourCompany />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup/aboutbusiness",
        element: (
          <ProtectedRoute>
            <AboutBusiness />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup/sharesandpaids",
        element: (
          <ProtectedRoute>
            <SharesAndPaids />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup/businesscontactdetails",
        element: (
          <ProtectedRoute>
            <BusinessContactDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup/whowillbethedirectors",
        element: (
          <ProtectedRoute>
            <WhoWillBeTheDirectors />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup/Shareholders",
        element: (
          <ProtectedRoute>
            <ShareHolders />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup/reviewSubmission",
        element: (
          <ProtectedRoute>
            <ReviewSubmission />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup/submittedsuccessfull",
        element: (
          <ProtectedRoute>
            <SubmittedSuccessfull />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup/selectplan",
        element: (
          <ProtectedRoute>
            <Selectplan />
          </ProtectedRoute>
        ),
      },
      {
        path: "/signup/makepayment",
        element: (
          <ProtectedRoute>
            <MakePayment />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/dashboards",
    element: (
      <ProtectedRoute role={["user"]}>
        {/* <Dashboard /> */}
        <UserDashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "index",
        element: (
          <ProtectedRoute>
            <Dashboards />
          </ProtectedRoute>
        ),
      },
      {
        path: "documents",
        element: (
          <ProtectedRoute>
            <Documents />
          </ProtectedRoute>
        ),
      },
      {
        path: "invoices",
        element: (
          <ProtectedRoute>
            <Invoices />
          </ProtectedRoute>
        ),
      },
      {
        path: "expenses",
        element: (
          <ProtectedRoute>
            <Expenses />
          </ProtectedRoute>
        ),
      },
      {
        path: "statements",
        element: (
          <ProtectedRoute>
            <Statements />
          </ProtectedRoute>
        ),
      },
      {
        path: "accounting",
        element: (
          <ProtectedRoute>
            <Accounting />
          </ProtectedRoute>
        ),
      },
      {
        path: "legal",
        element: (
          <ProtectedRoute>
            <Legal />
          </ProtectedRoute>
        ),
      },
      {
        path: "banks",
        element: (
          <ProtectedRoute>
            <Banks />
          </ProtectedRoute>
        ),
      },
      {
        path: "banks/:_id",
        element: (
          <ProtectedRoute>
            <BankDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: "perks",
        element: (
          <ProtectedRoute>
            <Perks />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        ),
      },
      {
        path: "updatepassword",
        element: (
          <ProtectedRoute>
            <UpdatePassword />
          </ProtectedRoute>
        ),
      },
      {
        path: "yourcompanies",
        element: (
          <ProtectedRoute>
            <YourCompanies />
          </ProtectedRoute>
        ),
      },
      {
        path: "register",
        element: (
          <ProtectedRoute>
            <Registercompany />
          </ProtectedRoute>
        ),
      },
      {
        path: "editCompanyInformation/:_id",
        element: (
          <ProtectedRoute>
            <EditCompanyInformation />
          </ProtectedRoute>
        ),
      },
      {
        path: "billing",
        element: (
          <ProtectedRoute>
            <Billing />
          </ProtectedRoute>
        ),
      },
      {
        path: "paymentDetails/:_id",
        element: (
          <ProtectedRoute>
            <UserPaymentDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: "company",
        element: (
          <ProtectedRoute>
            <Company />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "profile",
            element: (
              <ProtectedRoute>
                <CompanyProfile />
              </ProtectedRoute>
            ),
          },
          {
            path: "directors",

            element: (
              <ProtectedRoute>
                <CompanyDirectors />
              </ProtectedRoute>
            ),
          },
          {
            path: "shareholders",
            element: (
              <ProtectedRoute>
                <CompanyShareholders />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/admin/dashboards",
    element: (
      <ProtectedRoute role={["admin"]}>
        <AdminDashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "index",
        element: (
          <ProtectedRoute>
            <AdminIndex />
          </ProtectedRoute>
        ),
      },
      {
        path: "userManagement",
        element: (
          <ProtectedRoute>
            <UserManagement />
          </ProtectedRoute>
        ),
      },
      {
        path: "paymentRecords",
        element: (
          <ProtectedRoute>
            <PaymentRecords />
          </ProtectedRoute>
        ),
      },

      {
        path: "userDetails/:_id",
        element: (
          <ProtectedRoute>
            <UserDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: "adminCompanyDetails/:_id",
        element: (
          <ProtectedRoute>
            <AdminCompanyDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: "adminUpdatePassword",
        element: (
          <ProtectedRoute>
            <AdminUpdatePassword />
          </ProtectedRoute>
        ),
      },
      {
        path: "adminProfileSetting",
        element: (
          <ProtectedRoute>
            <AdminProfileSetting />
          </ProtectedRoute>
        ),
      },
      {
        path: "adminEditUserProfile/:_id",
        element: (
          <ProtectedRoute>
            <AdminEditUserProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: "editCompanyInformation/:_id",
        element: (
          <ProtectedRoute>
            <EditCompanyInformation />
          </ProtectedRoute>
        ),
      },
      {
        path: "allCompanyList",
        element: (
          <ProtectedRoute>
            <AllCompanyList />
          </ProtectedRoute>
        ),
      },

      {
        path: "paymentDetails/:_id",
        element: (
          <ProtectedRoute>
            <PaymentDetails />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

export default router;

import incBlue from "../../../assets/home/inc_blue.png";
export default function HomeNavigationBar() {
  return (
    <div className="d-flex justify-content-between align-items-center bg-white px-5">
      <div>
        <img style={{ width: "100px" }} className="" src={incBlue} alt="" />
      </div>
      <div className="d-md-flex  align-items-center gap-5 mt-md-3">
        <div className="font-weight-bold">+8801988-121220</div>
        <button className="home_custom_button2  btn btn-primary text-uppercase fw-bold rounded-pill px-4 py-2 ">
          INCORPORATE NOW
        </button>
      </div>
    </div>
  );
}

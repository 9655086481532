import bannerImage from "../../../assets/home/banner_image.png";
import tableMan from "../../../assets/home/table_man.svg";
export default function Banner() {
  return (
    <div className="p-md-5 p-4 banner_background">
      <div className="container-fluid d-flex flex-column align-items-center">
        <h2
          style={{ fontSize: "45px" }}
          className="text-white text-center   fw-bold position-relative d-md-block d-none"
        >
          The Fastest & Easiest Way To Set Up Your Corporation
        </h2>
        <h2
          className="text-white text-center   fw-bold position-relative d-block d-md-none"
          style={{ fontSize: "20px" }}
        >
          The Fastest & Easiest Way To Set Up Your Corporation
        </h2>
        <img
          loading="lazy"
          src={bannerImage}
          className="w-100 mt-md-5 "
          alt="Corporation setup illustration"
        />
        <img
          loading="lazy"
          src={tableMan}
          style={{ transform: "rotateY(180deg) " }}
          className="img-fluid w-25 shadow mt-4 mt-md-0 banner_secondary_image d-md-block d-none "
          alt="Additional corporation information"
        />
        <section className="container position-relative mt-n5 z-3">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <button
              className="btn btn-primary text-uppercase fw-bold rounded-pill px-md-4 py-md-3 mt-md-5 home_custom_button "
              aria-label="Launch corporation setup process"
            >
              Launch My Corporation
            </button>

            <button
              className="home_custom_button  btn btn-light text-uppercase fw-bold rounded-pill px-md-4 py-md-3 mt-md-5 "
              aria-label="Learn more about corporations"
            >
              Learn About Corporations
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}

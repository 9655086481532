import whiteLogo from "../../../assets/home/inc_white.png";
import fb from "../../../assets/home/fb.svg";
import linkedin from "../../../assets/home/linkedin.svg";
import x from "../../../assets/home/x.svg";
import { Link } from "react-router-dom";
export default function Footer() {
  const socialMedia = [
    {
      img: fb,

      url: "https://www.facebook.com/",
    },
    {
      img: linkedin,
      url: "https://www.linkedin.com/",
    },
    {
      img: x,
      url: "https://twitter.com/",
    },
  ];
  return (
    <div className="footer_background  pt-5 pb-2">
      <section className="text-white text-md-start text-center  d-md-flex  justify-content-evenly">
        <div className="mb-4 mb-md-0">
          <h3>+8801988-121220</h3>
          <div>
            Phone support is available <br />
            Monday – Friday from 9 a.m. to to 6 p.m.{" "}
          </div>
        </div>
        <hr className="md-d-none d-block" />
        <div className="mb-4 mb-md-0">
          <h5>Our Mailbox</h5>
          <div>admin@incorplink.com</div>
        </div>
        <hr className="md-d-none d-block" />
        <div className="mb-4 mb-md-0">
          <h5>Our Address</h5>
          <div>
            Level 8, MTB Building, <br /> Software Technology Park, <br />{" "}
            Jashore - 7400
          </div>
          <hr className="md-d-none d-block" />
        </div>
      </section>
      <section className="text-white text-md-start text-center p-md-0 p-3  d-md-flex  justify-content-evenly mt-md-5">
        <div>
          <div className="">
            Incorplink is NOT a law firm and does NOT provide legal advice. Use
            of our products <br />
            and services is governed by our{" "}
            <span className="fourth_banner_span_text_title">
              Terms of Service{" "}
            </span>
            and{" "}
            <span className="fourth_banner_span_text_title">
              Privacy Policy.{" "}
            </span>
            <br />
            The information you provide to Incorplink is NOT protected by
            attorney-client privilege.
          </div>
        </div>
        <hr className="md-d-none d-block" />
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={whiteLogo} className="w-md-75 w-50" alt="" />
          <div className="d-flex gap-2 justify-content-center align-items-center">
            {socialMedia.map((item, index) => (
              <div key={index}>
                <Link to={item.url} target="_blank" rel="noreferrer">
                  <img src={item.img} style={{ width: "20px" }} alt="" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className=" ">
        © 2024 Incorplink All Rights Reserved
      </div>
    </div>
  );
}

import React from "react";
import phone from "../../../assets/home/Phone.gif";
import laptop from "../../../assets/home/Laptop.gif";

export default function ThirdBanner() {
  return (
    <div className="mt-5">
      <div className="text-center mb-2 ">
        <h1 className="second_banner_text_title_main">Explore Our App</h1>
        <div>
          Follow below steps to incorporate your business with Incorplink
        </div>
      </div>
      <div className="d-md-flex justify-content-evenly align-items-center">
        <div className=" d-flex justify-content-center align-items-center w-md-25">
          <img
            style={{
              height: "250px",
            }}
            src={phone}
            alt=""
          />
        </div>
        <div className=" d-flex flex-column justify-content-center align-items-center w-md-50 text-decoration-none">
          <ul className="   text-decoration-none">
            <li>Step1 : Sign Up on Incorplink.</li>
            <li>Step2 : Fill in Your Business Information</li>
            <li>Step3 : Choose a Subscription Plan.</li>
            <li>Step4 : Complete the Payment.</li>
            <li>Step5 : Submit Required Documents.</li>
          </ul>
        </div>

        <div className=" d-flex justify-content-center align-items-center w-md-25">
          <img
            style={{
              height: "250px",
            }}
            src={laptop}
            alt=""
          />
        </div>
        <div></div>
      </div>
    </div>
  );
}

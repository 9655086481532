import React, { useState } from "react";
import phone from "../../../assets/home/mobile.png";

export default function FourthBanner() {
  const [isHovered, setIsHovered] = useState(false);
  const services = [
    "Incorporation of your company",
    "Annual Compliance with the State Renewal",
    "US Business Address",
    "US Business Number",
    "Physical documentation/letter forwarding support",
    "Employer Identification Number (EIN)",
    "US Business Bank Account",
    "Business Debit Card",
    "Bank Account Application Process",
    "Expert Guidance on Managing Your Financial Accounts",
    "Basic Tax Consultation",
    "ITIN (Individual Taxpayer Identification Number)",
    "Guidance to Business Stripe Account with Expert Hand",
    "Guidance to Business PayPal Account with Expert Hand",
  ];
  return (
    <div className="banner_background d-md-flex pt-5  mt-5 justify-content-center">
      <div>
        <div>
          <h1 className="fourth_banner_text_title">
            Everything you need to build <br />
            your business, <span className="fourth_banner_span_text_title">all in one place.</span>
          </h1>
        </div>
        <section className="d-md-flex justify-content-center  align-items-center gap_cs    w-100    ">
          <div className="  flex justify-content-center">
            <ul className="ul-padding   text-white">
              {services?.map((service, index) => (
                <li key={index}>
                  <div className="cs-line-height">{service}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="  d-flex justify-content-center ">
            <img className=" phone-res-s4" src={phone} alt="" />
          </div>
        </section>
      </div>
    </div>
  );
}

import staticsMan from "../../../assets/home/statics_man.png";
export default function SecondBanner() {
  return (
    <div className="d-flex  justify-content-center w-100 mt-5">
      <div>
        <div className="text-center mb-2">
          <h1 className="second_banner_text_title_main">
            Plan, Start & Grow Your Business <br />
            with Incorplink
          </h1>
          <div className="secondary_responsive_title">
            Think of us as the point between your business idea <br /> and your
            business launch.
          </div>
        </div>
        <div className="d-md-flex justify-content-between align-items-center   w-md-100">
          <div className="w-md-50 res-sc-title">
            <div className="w-100 mb-2">
              <h1 className="second_banner_text_title">Launch Your Business</h1>
              <div className=" secondary_responsive_title">
                Build business with Incorplink in your dream destination.
              </div>
            </div>

            <div className="mb-2  position-relative  ">
              <svg
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "10px",
                  height: "36px",
                  width: "36px",
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="34"
                viewBox="0 0 23 34"
                fill="none"
              >
                <mask
                  id="mask0_2309_2473"
                  style={{ maskType: "luminance" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="23"
                  height="34"
                >
                  <path d="M0 0H22.978V33.0425H0V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_2309_2473)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.4895 0.0078125C17.8349 0.0078125 22.979 5.15682 22.979 11.5073C22.979 13.7166 22.3553 15.7802 21.2766 17.5324C19.9814 19.6788 18.6861 21.8263 17.3919 23.9727C15.8143 26.5382 14.539 28.6148 12.9613 31.1803C11.5713 33.4425 11.4775 33.4973 10.0795 31.2242C8.51084 28.6716 7.19165 26.5252 5.623 23.9727C4.33076 21.8502 3.03951 19.7277 1.74727 17.6053C0.640633 15.837 0 13.7475 0 11.5073C0 5.15682 5.14402 0.0078125 11.4895 0.0078125ZM11.4895 5.77351C14.3344 5.77351 16.6405 8.08158 16.6405 10.9285C16.6405 13.7754 14.3344 16.0835 11.4895 16.0835C8.64455 16.0835 6.33847 13.7754 6.33847 10.9285C6.33847 8.08158 8.64455 5.77351 11.4895 5.77351Z"
                    fill="#434343"
                    fillOpacity={0.8}
                  />
                </g>
              </svg>

              <input
                placeholder="Where are you from?"
                className="second_banner_input_field w-100"
                type="text"
              />
            </div>
            <div className="  position-relative  ">
              <svg
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "10px",
                  height: "30px",
                  width: "30px",
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="36"
                viewBox="0 0 46 36"
                fill="none"
              >
                <mask
                  id="mask0_2309_2485"
                  style={{ maskType: "luminance" }}
                  maskUnits="userSpaceOnUse"
                  x={2}
                  y={22}
                  width={42}
                  height={14}
                >
                  <path
                    d="M2.94116 22.8281H43.0476V36H2.94116V22.8281Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_2309_2485)">
                  <path
                    d="M42.9972 23.0781V34.9099C42.9972 35.5026 42.5392 35.9876 41.9195 35.9876H4.03935C3.44661 35.9876 2.96265 35.5026 2.96265 34.9099V23.1051C3.87769 23.5361 4.87357 23.7517 5.89738 23.7517H17.77V24.1558C17.77 25.0449 18.4965 25.7734 19.3856 25.7734H26.5743C27.4624 25.7734 28.1888 25.0449 28.1888 24.1558V23.7517H40.0625C41.0853 23.7517 42.0812 23.5092 42.9972 23.0781Z"
                    fill="#434343"
                    fillOpacity={0.8}
                  />
                </g>
                <mask
                  id="mask1_2309_2485"
                  style={{ maskType: "luminance" }}
                  maskUnits="userSpaceOnUse"
                  x={0}
                  y={6}
                  width={46}
                  height={17}
                >
                  <path d="M0 6.73438H45.9569V22.828H0V6.73438Z" fill="white" />
                </mask>
                <g mask="url(#mask1_2309_2485)">
                  <path
                    d="M1.0767 6.77344H44.8802C45.473 6.77344 45.9569 7.2584 45.9569 7.85114V16.7731C45.9569 18.3896 45.2844 19.8725 44.2346 20.9502C43.1569 22.0009 41.6761 22.6755 40.0615 22.6755H28.1878V18.713C28.1878 17.8239 27.4614 17.0964 26.5733 17.0964H19.3846C18.4955 17.0964 17.7691 17.8239 17.7691 18.713V22.6755H5.89642C4.28087 22.6755 2.80002 22.0009 1.75027 20.9502C0.672565 19.8725 0 18.4166 0 16.7731V7.85114C0 7.2584 0.484965 6.77344 1.0767 6.77344Z"
                    fill="#434343"
                    fillOpacity={0.8}
                  />
                </g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.3848 18.1719C19.0884 18.1719 18.8459 18.4144 18.8459 18.7107V24.1551C18.8459 24.4515 19.0884 24.694 19.3848 24.694H26.5735C26.8688 24.694 27.1113 24.4515 27.1113 24.1551V18.7107C27.1113 18.4144 26.8688 18.1719 26.5735 18.1719H19.3848Z"
                  fill="#434343"
                  fillOpacity={0.8}
                />
                <mask
                  id="mask2_2309_2485"
                  style={{ maskType: "luminance" }}
                  maskUnits="userSpaceOnUse"
                  x={13}
                  y={0}
                  width={20}
                  height={10}
                >
                  <path
                    d="M13.9258 0H32.0631V9.03372H13.9258V0Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask2_2309_2485)">
                  <path
                    d="M30.7721 8.92877H15.1844C14.5916 8.92877 14.1077 8.44381 14.1077 7.85008V4.45432C14.1077 3.24191 14.6186 2.13627 15.4269 1.328C16.2341 0.51972 17.3378 0.0078125 18.5761 0.0078125H27.3803C28.6187 0.0078125 29.7223 0.51972 30.5306 1.328C31.3379 2.13627 31.8498 3.24191 31.8498 4.45432V7.85008C31.8498 8.44381 31.3648 8.92877 30.7721 8.92877ZM16.2611 6.77238H29.6954V4.45432C29.6954 3.83465 29.426 3.26885 29.0228 2.83777C28.5918 2.43264 28.026 2.16321 27.3803 2.16321H18.5761C17.9305 2.16321 17.3647 2.43264 16.9616 2.83777C16.5305 3.26885 16.2611 3.83465 16.2611 4.45432V6.77238Z"
                    fill="#434343"
                    fillOpacity={0.8}
                  />
                </g>
              </svg>{" "}
              <input
                placeholder="Where are you from?"
                className="second_banner_input_field w-100"
                type="text"
              />
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <button className="home_custom_button">
                Launch My Corporation
              </button>
            </div>
          </div>
          <div style={{
            paddingLeft : "80px"
          }} className="w-md-50  ">
            <img className="w-md-100 w-75 d-md-block d-flex justify-content-center align-items-center" src={staticsMan} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
